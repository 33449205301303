<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half d-table w-100" style="background: url('/images/about-top.jpeg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <h1 class="text-white title-dark">網站/系統建置</h1>
              <p class="text-white-50 para-desc mb-0 mx-auto">各種類型的網站或是系統，我們都能協助</p>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">首頁</router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">服務項目</li>
                    <li class="breadcrumb-item active" aria-current="page">網站/系統建置</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="card explore-feature border-0 rounded text-center bg-white shadow">
              <div class="card-body">
                <div class="icon rounded-circle shadow-lg d-inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="30px"
                    height="30px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                      <path d="M3 9h18" />
                      <path d="M9 21V9" />
                    </g>
                  </svg>
                </div>
                <h5 class="mt-3 title">形象網站</h5>
                <p
                  class="text-muted mb-0 text-left mt-3 mb-3"
                >協助您的企業或組織建立形象網站，包含網站的設計與規劃，並會會提供您一個內容管理的後台，方便您針對網站的部分進行客製化調整。</p>
                <ul class="list-unstyled text-muted text-left">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> RWD 響應式
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 重點 SEO 優化
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 客製化表單、互動介面
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> CMS 管理後台
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card explore-feature border-0 rounded text-center bg-white shadow">
              <div class="card-body">
                <div class="icon rounded-circle shadow-lg d-inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="30px"
                    height="30px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <ellipse cx="12" cy="5" rx="9" ry="3" />
                      <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" />
                      <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" />
                    </g>
                  </svg>
                </div>
                <h5 class="mt-3 title">後台系統</h5>
                <p
                  class="text-muted mb-0 text-left mt-3 mb-3"
                >各式管理後台的開發，滿足每個企業的內部或外部需求，並協助串接第三方 API。</p>
                <ul class="list-unstyled text-muted text-left">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 關聯資料管理
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 彈性權限系統
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 多元登入
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> API 串接/提供
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 易用且美觀的介面
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card explore-feature border-0 rounded text-center bg-white shadow">
              <div class="card-body">
                <div class="icon rounded-circle shadow-lg d-inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="30px"
                    height="30px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="9" cy="21" r="1" />
                      <circle cx="20" cy="21" r="1" />
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                    </g>
                  </svg>
                </div>
                <h5 class="mt-3 title">電商網站</h5>
                <p
                  class="text-muted mb-0 text-left mt-3 mb-3"
                >無論是簡單的一頁式電商，到多店的電商平台，嗨嗨數位都能協助您建置屬於你理想中的電商網站，搭配金物流串接，讓您的生意一飛沖天</p>
                <ul class="list-unstyled text-muted text-left">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 金流/物流串接
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 各式電商模組系統
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 多元登入
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span> 客製化商店頁面
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">您有其他需求嗎？</h4>
              <p class="text-muted para-desc mx-auto">
                都歡迎跟嗨嗨 Say Hi，讓我們提供專屬於您的解決方案
              </p>
              <div class="mt-4">
                <router-link :to="{name: 'Contact'}" class="btn btn-primary mt-2 mr-2">
                  聯絡我們
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
<script>
import { ArrowUpIcon, ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Page-aboutus-two component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon
  }
};
</script>